<template>
  <div class="checkbox-input__wrap">
    <div :class="['checkbox-input', disabled && 'checkbox-input--disabled']">
      <div v-if="!handleGetId" class="checkbox-input__checkbox-container">
        <input
          type="checkbox"
          :id="id"
          v-model="setValue[id]"
          :value="setValue[id]"
          :disabled="disabled"
        />
        <div
          @click="setValue[id] = !setValue[id]"
          :class="['checkbox-input__checkbox', setValue[id] && 'checkbox-input__checkbox--checked']"
        >
          <Check v-if="setValue[id]" />
        </div>
      </div>
      <div v-else class="checkbox-input__checkbox-container_no-margin">
        <input
          type="checkbox"
          :id="id"
          @change="handleGetId(id)"
          :value="checkId"
          :disabled="disabled"
        />
        <div
          @click="handleGetId(id)"
          :class="['checkbox-input__checkbox', checkId && 'checkbox-input__checkbox--checked']"
        >
          <Check v-if="checkId" />
        </div>
      </div>
      <label :for="id">{{ label }}<slot /></label>
    </div>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import Check from "../../../assets/images/icons/check.svg";

export default {
  name: "InputCheckboxMain",
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    handleGetId: {
      type: [Function, Boolean],
      default: false,
    },
    arrayId: {
      type: Array,
      default() {
        return [];
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Check,
  },
  computed: {
    checkId() {
      const index = this.arrayId.indexOf(this.id);
      if (index === -1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-input {
  display: flex;

  input {
    display: none;
  }

  &__checkbox-container {
    margin-right: 20px;
  }

  &__checkbox {
    position: relative;
    width: 18px;
    height: 18px;
    border: 1px solid #131313;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;

    &--checked {
      background: #131313;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--disabled {
    label {
      color: $mainDisabled;
      margin-left: 20px;
    }
  }

  label {
    font-size: $g-font-base-size;
  }
}
</style>
