<template>
  <div :class="['modal', modalCenter ? 'center' : '']" @click.self="handleCloseModal">
    <div class="modal-container">
      <slot />
      <CloseModalIcon @click="handleCloseModal" class="close-modal-btn" />
    </div>
  </div>
</template>

<script>
import CloseModalIcon from "../../assets/images/icons/delete_item.svg";

export default {
  name: "DefaultModal",
  props: {
    onClose: {
      type: Function,
      default() {
        return {};
      },
    },
    modalCenter: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CloseModalIcon,
  },
  methods: {
    handleCloseModal() {
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1500;

  .modal-container {
    @include flex-row;

    position: absolute;
    background: #2f2f34;
    bottom: 0;
    width: 100%;
    padding: 12px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.25);
  }

  svg {
    position: absolute;
    right: 5px;
    cursor: pointer;
  }
}
.modal.center {
  background: rgba(0, 0, 0, 0.5);

  .modal-container {
    @include flex-column;

    position: absolute;
    background: $g-color-white;
    width: 782px;
    top: 50%;
    left: 50%;
    padding: 25px 30px;
    transform: translate(-50%, -50%);
  }

  svg {
    top: 10px;
    right: 10px;

    path {
      fill: $mainColor;
    }
  }
}
</style>
