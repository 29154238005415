<template>
  <Main>
    <div class="client-create">
      <div class="client-create__container">
        <h1 v-if="!triggerUpdate" class="client-create__title">Создать заказчика</h1>
        <div v-else class="client-create__header">
          <h1>Редактировать заказчика</h1>
          <div v-if="client != null" class="client-create__data">
            Дата регистрации: {{ dateTransform }}
          </div>
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="client-create__form-container">
            <div class="client-create__form-item">
              <p class="client-create__label">Имя</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSend"
                  :id="'name'"
                  :label="'name'"
                  :type="'text'"
                  :placeholder="'Введите имя'"
                  :error-messages="error['name']"
                  isBordered
                  isAverage
                />
              </div>
              <p class="client-create__label">Телефон</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSend"
                  :id="'phone'"
                  :label="'phone'"
                  :type="'phone'"
                  :placeholder="'+38 (000) 000 00 00'"
                  :error-messages="error['phone']"
                  :thisPhone="true"
                  isBordered
                  isAverage
                />
              </div>
              <div class="input-container">
                <InputCheckboxMain
                  :label="'Юридическое лицо'"
                  :id="'isLegalEntity'"
                  :set-value="formDataToSend"
                />
              </div>
            </div>
            <div v-if="formDataToSend.isLegalEntity" class="client-create__form-item">
              <p class="client-create__label">Код компании:</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSend"
                  :id="'companyName'"
                  :label="'companyName'"
                  :type="'text'"
                  :placeholder="'Код компании'"
                  :error-messages="error['companyName']"
                  isBordered
                  isAverage
                />
              </div>
              <p class="client-create__label">Код ЕГРПОУ:</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSend"
                  :id="'codeUsreou'"
                  :label="'codeUsreou'"
                  :type="'text'"
                  :placeholder="'Код ЕГРПОУ'"
                  :error-messages="error['codeUsreou']"
                  isBordered
                  isAverage
                />
              </div>
            </div>
            <div v-if="formDataToSend.isLegalEntity" class="client-create__form-item">
              <p class="client-create__label">Еmail:</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSend"
                  :id="'email'"
                  :label="'email'"
                  :type="'email'"
                  :placeholder="'Еmail'"
                  :error-messages="error['email']"
                  isBordered
                  isAverage
                />
              </div>
            </div>
          </div>

          <ButtonMain v-if="!triggerUpdate" :type="'submit'">Создать</ButtonMain>
        </form>
        <ButtonMain v-if="triggerUpdate" @click.native="handleOpenConfirmModal()"
          >Редактировать</ButtonMain
        >
      </div>
    </div>
    <DefaultModal
      class="clients__confirm-modal"
      v-if="updateModal"
      :on-close="handleCloseConfirmModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseConfirmModal" :on-success="handleSubmit">
        <template v-slot:description>
          <p>Вы уверены, что хотите редактировать заказчика {{ client.name }}?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal
      v-if="Object.keys(error).length != 0 && error.message"
      :on-close="handleCloseErrorModal"
    >
      <ErrorModalContent :errorMessages="[error.message]" />
    </DefaultModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import clientsApi from "../api/clients";
import { dateTransform } from "../helpers/dateHelper";
import { phoneNumberIteration } from "../helpers/dataIteration";
import ROUTE from "../constants/routes";

import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";
import Main from "../templets/Main.vue";
import InputText from "../components/atoms/inputs/InputText.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import DefaultModal from "../components/molecules/DefaultModal.vue";
import ConfirmModalContent from "../components/molecules/ConfirmModalContent.vue";
import ErrorModalContent from "../components/molecules/ErrorModalContent.vue";
import InputCheckboxMain from "../components/atoms/inputs/InputCheckboxMain.vue";

export default {
  name: "ClientCreate",
  data() {
    return {
      formDataToSend: {
        name: "",
        phone: "",
        isLegalEntity: false,
        companyName: "",
        codeUsreou: "",
        email: "",
      },
      updateModal: false,
    };
  },
  components: {
    Main,
    InputText,
    ButtonMain,
    FetchSpinnerModal,
    DefaultModal,
    ConfirmModalContent,
    ErrorModalContent,
    InputCheckboxMain,
  },
  methods: {
    handleSubmit() {
      this.formDataToSend.phone = phoneNumberIteration(this.formDataToSend.phone);
      const data = {
        name: this.formDataToSend.name,
        phone: this.formDataToSend.phone,
        is_legal_entity: this.formDataToSend.isLegalEntity,
      };
      if (this.formDataToSend.isLegalEntity) {
        data.company_name = this.formDataToSend.companyName;
        data.code_usreou = this.formDataToSend.codeUsreou;
        data.email = this.formDataToSend.email;
      }
      if (this.triggerUpdate) {
        const url = `/${this.client.id}`;
        this.$store.commit("clients/setLoading", true);
        clientsApi
          .clientUpdate(url, data)
          .then((res) => {
            if (res.name || res.phone) {
              this.$store.commit("clients/setError", res);
              return;
            }
            this.$store.commit("clients/setError", {});
            this.$router.push({
              path: ROUTE.CLIENT_DETAIL_PAGE.replace(":clientId", this.client.id),
            });
          })
          .catch((error) => {
            const { data } = error.response;
            this.$store.commit("clients/setError", data);
          })
          .finally(() => {
            this.$store.commit("clients/setLoading", false);
            this.handleCloseConfirmModal();
          });
      } else {
        this.$store.dispatch("clients/createClient", data);
      }
    },
    getClientDetail() {
      const { params } = this.$route;
      const url = `/${params.clientId}`;
      this.$store.dispatch("clients/getClientDetail", url);
    },
    handleOpenConfirmModal() {
      this.updateModal = true;
    },
    handleCloseConfirmModal() {
      this.updateModal = false;
    },
    handleCloseErrorModal() {
      this.$store.commit("clients/setError", {});
    },
  },
  computed: {
    ...mapState("clients", ["error", "loadingProcess", "client"]),
    triggerUpdate() {
      if (this.$route.params.process === "update") {
        return true;
      }
      return false;
    },
    dateTransform() {
      return dateTransform(this.client.createdAt);
    },
  },
  mounted() {
    if (this.triggerUpdate) {
      const { params } = this.$route;
      const url = `/${params.clientId}`;
      clientsApi.getClientDetail(url).then((res) => {
        this.formDataToSend.name = res.data.name;
        this.formDataToSend.phone = res.data.phone;
        this.formDataToSend.isLegalEntity = res.data.isLegalEntity;
        this.formDataToSend.codeUsreou = res.data.codeUsreou;
        this.formDataToSend.companyName = res.data.companyName;
        this.formDataToSend.email = res.data.email;
      });
      this.getClientDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  width: 328px;
  padding: 5px 0 15px;
}

.client-create {
  width: 100%;

  &__header {
    @include flex-row;

    justify-content: space-between;
    padding-right: 70px;
    padding-bottom: 20px;
  }

  &__data {
    display: flex;
    align-items: flex-end;
    color: $mainSecondTextColor;
  }

  &__container {
    padding: 26px 18px;
  }

  &__title {
    padding-bottom: 10px;
  }

  &__label {
    color: $mainSecondTextColor;
    font-size: $g-font-base-size;
  }

  &__form-container {
    display: flex;
  }

  &__form-item {
    padding-right: 50px;
  }

  /deep/.button-main {
    width: 328px;
  }
}
</style>
